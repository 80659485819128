import React, { useEffect, useState } from 'react'
import AppNavbar from '../../Common/App_Navbar/AppNavbar'
import { handleGetApis, handlePostApis } from '../../../Utilities/Utils'
import { useSelector } from 'react-redux'
import { formatDateTime } from '../../Services/Functions'
import LineChart from '../../Common/LineChart/LineChart'
import "../Crowd_Eye/CrowdEye.css"
import loaderImage from "../../../Assets/loaderImage.png";
import { useNavigate } from 'react-router-dom';
import dwn from "../../../Assets/save-button.jpg";

function OldCrowdEye() {
  const [data, setData] = useState(null)
  const [chartData, setChartData] = useState(null)
  const [dataType, setDataType] = useState("plain")
  const [serviceSelected, setServiceSelected] = useState("crowdeye")
  const [timeScale, setTimeScale] = useState('h')
  const [deviceID, setDeviceID] = useState('HAR001')
  const [deviceInfo, setDeviceInfo] = useState()
  const [lastRefresh, setLastRefresh] = useState(null)
  const [from, setFrom] = useState(null)
  const [to, setTo] = useState(null)
  const [devices, setDevices] = useState(null)
  const [total, setTotal] = useState({})
  const handleToggle = () => {
    setDataType(dataType === "plain" ? "cumulative" : "plain");
  };
  const nav = useNavigate();
  const sliceData = useSelector((state) => state.functionslice.value);
  function calculateCumulativeSums(data) {
    let cumulativeEntries = 0;
    let cumulativeExits = 0;

    return data && data?.map(item => {
      let newItem = { ...item, data: { ...item?.data } };

      let entryCount = item?.data['total entry count'];
      let exitCount = item?.data['total exit count'];

      if (entryCount != null && entryCount !== undefined) {
        cumulativeEntries += entryCount;
        newItem.data['total entry count'] = cumulativeEntries;
      } else {
        newItem.data['total entry count'] = undefined;
      }

      if (exitCount != null && exitCount !== undefined) {
        cumulativeExits += exitCount;
        newItem.data['total exit count'] = cumulativeExits;
      } else {
        newItem.data['total exit count'] = undefined;
      }

      return newItem;
    });
  }


  useEffect(() => {
    if (sliceData?.userData?.phone) {
      const response = handlePostApis("devices/Oldlist", { phone: sliceData.userData.phone });
      response.then(function (result) {
        if (result) {
          setDevices(result?.data)
        }
      })
    }
  }, [sliceData?.userData?.phone])
  useEffect(() => {
    if (deviceID && sliceData?.userData?.permissions) {
      const headers = {
        'content-type': "application/json",
        'permissions': sliceData.userData.permissions
      }
      const response = handleGetApis(`devices/${deviceID}`, headers);
      response.then(function (result) {
        if (result) {
          setDeviceInfo(result);
        }
      })
    }

  }, [deviceID, sliceData?.userData?.permissions])

  useEffect(() => {
    const now = new Date();
    const currentTime = formatDateTime(now);
    setTo(currentTime);

    now.setHours(now.getHours() - 6);
    const adjustedTime = formatDateTime(now);
    setFrom(adjustedTime);
  }, [serviceSelected]);

  useEffect(() => {
    // h d w m
    if (timeScale === 'h') {
      const now = new Date();
      const currentTime = formatDateTime(now);
      setTo(currentTime);
      now.setHours(now.getHours() - 6);
      const adjustedTime = formatDateTime(now);
      setFrom(adjustedTime);
    }
    else if (timeScale === 'd') {
      const now = new Date();
      const currentTime = formatDateTime(now);
      setTo(currentTime);

      now.setHours(now.getHours() - 24);
      const adjustedTime = formatDateTime(now);
      setFrom(adjustedTime);
    }
    else if (timeScale === 'w') {
      const now = new Date();
      const currentTime = formatDateTime(now);
      setTo(currentTime);

      now.setDate(now.getDate() - 7)
      const adjustedTime = formatDateTime(now);
      setFrom(adjustedTime);
    }
    else if (timeScale === 'm') {
      const now = new Date();
      const currentTime = formatDateTime(now);
      setTo(currentTime);

      now.setMonth(now.getMonth() - 1)
      const adjustedTime = formatDateTime(now);
      setFrom(adjustedTime);
    }
  }, [timeScale])
  const deviceSelection = (deviceId, from, to) => {
    console.log("Sending data:", { deviceId, from, to });
    const response = handlePostApis("devices/data", { deviceId, from, to });
    response.then(function (result) {
      if (result) {
        console.log(result, "line 129")
        setData(result?.data);
        setLastRefresh((new Date(result?.data[result?.data?.length - 1]?.data?.datetime)).toLocaleString('en-us', {
          weekday: "long", year: "numeric", month: "short", day: "numeric", hour: "2-digit", minute: "2-digit", second: "2-digit"
        }))
        const totalEntries = result?.data.reduce((acc, curr) => acc + (curr?.data['total entry count'] ?? 0), 0)
        const totalExits = result?.data.reduce((acc, curr) => acc + (curr?.data['total exit count'] ?? 0), 0)
        setTotal({
          entry: totalEntries,
          exit: totalExits
        })
        if (dataType === 'cumulative') {
          let newData = calculateCumulativeSums(result?.data);
          setChartData(newData);
        }
        else {
          setChartData(result?.data);
        }
      }
    })
  };
  useEffect(() => {
    deviceSelection(deviceID, from, to)
    console.log(from, to, deviceID, dataType)
  }, [from, to, deviceID, dataType])
  const handleAggregate = (e) => {
    const data = e.target.value;
    console.log(data, "line 163")
    if (data === "Aggregate") {
      nav("/app/oldCrowdeye/aggregate");
    }
  }

  const handleDownloadData = () => {
    const csvRows = [];
    const headers = ['id', 'address', 'deviceID', ...Object.keys(chartData[0].data)];  // Add 'address' and 'deviceID'
    csvRows.push(headers.join(','));

    chartData.forEach(row => {
      const values = [
        row.id,
        `"${deviceInfo?.Address}"`,
        deviceID,
        ...headers.slice(3).map(header => row.data[header])
      ];
      csvRows.push(values.join(','));
    });

    const csvString = csvRows.join('\n');
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${deviceID}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  if (data && data !== null) {
    return (
      <>
        <AppNavbar />
        <section className="mainContainer">
          <section className="dashboardContainer">
            <h1 className='pagetag'>Crowdeye Dashboard</h1>
            {data && data ?
              <>
                <select onChange={(e) => { { setDeviceID(e.target.value) }; handleAggregate(e) }}>
                  <option value="default" disabled selected>Select a Device</option>
                  {devices?.map((item) => (
                    <option value={item}>{item}</option>
                  ))}
                  <option style={{ display: devices?.length !== 0 ? "flex" : "none" }} value="Aggregate">Aggregate Analysis</option>
                </select>

                {deviceInfo ?
                  <>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                      <div>

                        <p className='location'>Location: - {deviceInfo.Address ?? ""}</p>
                        {deviceInfo.Location ?
                          <p className='updated'>Latitude: - {deviceInfo.Location._latitude}, Longitude: - {deviceInfo.Location._longitude}</p>
                          : <></>}
                        <p>Last Updated on: {lastRefresh}</p>
                      </div>
                      {/* <div>
                                <button className={refreshButton}>Refresh</button>
                            </div> */}
                    </div>
                    {total &&
                      <div className="infoCont">
                        <div className="infoBox">
                          <h2>{total?.entry}</h2>
                          <p>Number of entries</p>
                        </div>
                        <div className="infoBox" style={{ border: "3px solid red" }}>
                          <h2>{total?.exit}</h2>
                          <p>Number of exits</p>
                        </div>
                      </div>
                    }
                    <div className="options_tab">
                      <select onChange={(e) => {
                        setTimeScale(e.target.value)
                      }}>
                        <option value="h" selected>Last 6 hours</option>
                        <option value="d">Last 24 hours</option>
                        <option value="w">Last week</option>
                        <option value="m">Last month</option>
                      </select>

                      <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>

                        <div className={`toggle_switch ${dataType === 'plain' ? "plain" : "cumulative"}`} onClick={handleToggle}>
                          <div className="switch"></div><br />
                        </div>
                        <p>Cumulative</p>
                      </div>
                    </div>
                    <div className='linechart'>
                      <button style={{ display: chartData && chartData.length !== 0 ? "flex" : "none" }} className='dwn-btn' onClick={handleDownloadData}> <img src={dwn} /></button>
                      <LineChart chartData={chartData} timeScale={timeScale} />
                    </div>
                  </>
                  : <p>Please Select a device before continuing...</p>}
              </>
              : <p>Select a service</p>}
          </section>
        </section >
      </>
    )
  } else {
    return (
      <>
        <AppNavbar />
        <div style={{ height: "100vh", width: "100vw", display: "flex", alignItems: "center", justifyContent: "center" }}>
          <img style={{ height: "15rem" }} src={loaderImage} alt="" className="signup-page-loaderImg" />
        </div>
      </>
    )
  }

}

export default OldCrowdEye
