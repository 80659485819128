import React, { useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import Home from '../Components/Web/Home/Home'
import About from '../Components/Web/About/About'
import Products from '../Components/Web/Products/Products'
import Team from '../Components/Web/Team/Team'
import Contact from '../Components/Web/Contact/Contact'
import Login from '../Components/Other/Login/Login'
import Register from '../Components/Other/Register/Register'
import CrowdEye from '../Components/Dashboard_Components/Crowd_Eye/CrowdEye'
import Aggregate from '../Components/Dashboard_Components/Aggregate_Analysis/Aggregate'
import TransitEye from '../Components/Dashboard_Components/TransitEye/TransitEye'
import Dashboard from '../Components/Dashboard_Components/Dashboard/Dashboard'
import OldCrowdEye from '../Components/Dashboard_Components/OldUsers/OldUsers'
import AggregateOld from '../Components/Dashboard_Components/Aggregate_Analysis/AggregateOld'

function Routing() {
  const location = useLocation()

  useEffect(() => {
    window.scrollTo(0, parseInt(0, 0))
  }, [location])

  return (
    <>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path="products/:name" element={<Products />} />
        <Route path='/team' element={<Team />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/app/login' element={<Login />} />
        <Route path='/app/register' element={<Register />} />
        <Route path='/app/dashboard' element={<Dashboard />} />
        <Route path='/app/crowdeye' element={<CrowdEye />} />
        <Route path='/app/oldCrowdeye/aggregate' element={<AggregateOld />} />
        <Route path='/app/crowdeye/aggregate' element={<Aggregate />} />
        <Route path='/app/transiteye' element={<TransitEye />} />
        <Route path='/app/oldCrowdeye' element={<OldCrowdEye />} />
      </Routes>
    </>
  )
}

export default Routing
