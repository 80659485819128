import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import "./AppNavbar.css";
import { Link, useNavigate } from 'react-router-dom';
import { handlePostApis } from '../../../Utilities/Utils';
import { setUsersData } from '../../../Toolkit/Slices/FunctionSlice';
function AppNavbar() {
  const nav = useNavigate();
  const dispatch = useDispatch();
  const handleUserAuthentication = () => {
    const userData = window.sessionStorage.getItem("user");
    if (userData) {
      try {
        const parsedUserData = JSON.parse(userData);
        dispatch(setUsersData(parsedUserData));
      } catch (error) {
        console.error("Failed to parse user data:", error);
        alert("There was an issue with your login session. Please log in again.");
        nav("/app/login");
      }
    } else {
      alert("Please log in first.");
      nav("/app/login");
    }
  };
  useEffect(() => {
    handleUserAuthentication();
  }, [])
  const sliceData = useSelector((state) => state.functionslice.value);
  const [listData, setListData] = useState([])
  const [dispValue, setDispValue] = useState("")

  const handleFetch = () => {
    if (sliceData?.userData?.phone !== undefined) {
      const data = handlePostApis("devices/selectoption", { phone: sliceData?.userData?.phone })
      data.then(function (result) {
        setListData(result?.data);
      })
    }
  }
  useEffect(() => {
    handleFetch();
  }, [sliceData?.userData?.phone])

  useEffect(() => {
    const path = window.location.pathname;
    const navigationRoutes = {
      "/app/aggregate": "aggregate",
      "/app/crowdeye": "CrowdEye",
      "/app/transiteye": "TransitEye",
      "/app/oldCrowdeye": "OldListUsers"
    };

    if (navigationRoutes[path]) {
      setDispValue(navigationRoutes[path]);
    }
  }, []);

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    if (!selectedValue) return;
    const filterData = listData?.allApplications.find(x => x.id === selectedValue);
    const navigationRoutes = {
      // aggregate: "/app/aggregate",
      CrowdEye: "/app/crowdeye",
      TransitEye: "/app/transiteye",
      OldListUsers: "/app/oldCrowdeye"
    };

    const filteredValue = listData.userApplications.find(x => x.name === filterData.id
      || x.name === filterData.name
    );
    if (!filteredValue) {
      alert("You cannot access it");
    } else {
      if (navigationRoutes[selectedValue]) {
        nav(navigationRoutes[selectedValue]);
      }
    }
  };
  const handleSignOut = () => {
    sessionStorage.removeItem("user"); nav("/app/login")
  }
  return (
    <>
      <nav className="nav">
        <div className="navLogoContainer">
          {/* <img src="/Images/iitr-white.png" alt="" /> */}
          <Link to='/app/dashboard'> <img src={window.innerWidth > 900 ? "/Images/logo.svg" : "/Images/smallLogo.png"} alt="" /></Link>
        </div>
        <div className='name'>Logged In as: {sliceData.userData.name}</div>
        <div>
          <select className="nav_select" value={dispValue} onChange={handleSelectChange}>
            <option value="">---SELECT---</option>
            {
              listData && listData?.allApplications?.map((item, index) => {
                return (
                  <option selected={item.id === dispValue || item.name === dispValue} style={{ display: item.id === "MobileAppUsers" ? "none" : "flex" }} key={index} value={item.id
                  }>{item.displayName}</option>
                )
              })
            }
          </select>
        </div>
        <button className='signoutbtn' onClick={handleSignOut}>Sign Out</button>
        {/* <SignOut onSignOut={() => {
          window.sessionStorage.removeItem('user')
          setUser(null)
        }} /> */}
      </nav>
    </>
  )
}

export default AppNavbar
