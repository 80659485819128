import React, { useState } from 'react';
import "./Team.css";
import { team } from '../../Services/Card';
import { useSelector } from 'react-redux';
import { send } from "@emailjs/browser"
import TeamCard from './Team_Card/TeamCard';
import Navbar from '../../Common/Navbar/Navbar';
import Footer from '../../Common/Footer/Footer';
function Team() {
  const sliceData = useSelector((state) => state.functionslice.value);
  const [formInput, setFormInput] = useState({
    name: "",
    email: "",
    subject: "",
    phone: "",
    message: "",
  })
  const handleChange = (e) => {
    const fieldName = e.target.name
    const value = e.target.value

    setFormInput({ ...formInput, [fieldName]: value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const name = formInput.name.trim()
    const email = formInput.email.trim()
    const subject = formInput.subject.trim()
    const message = formInput.message.trim()
    if (name === "" || email === "" || subject === "" || message === "") {
      if (name === "") alert("Name is a necessary field")
      else if (email === "") alert("Email is a necessary field")
      else if (subject === "") alert("Subject is a necessary field")
      else if (message === "") alert("Message is a necessary field")
    } else {
      send("service_vlvo6ni", "template_fo0s97s", {
        from_name: name,
        email: email,
        phone: formInput.phone,
        subject: `Email from hiring section:  ${subject}`,
        message: message,
      }).then(
        (res) => {
          alert("Email Sent")
          window.location.reload()
        },
        (err) => {
          alert("Something Went Wrong. Please try again later.")
        }
      )
    }
  }

  return (
    <>
      <Navbar />
      <main className="teamPage">
        <section className="aboutTeam">
          <h1>Our Team</h1>
          <div className="teamcards">
            {team.map((member) => (
              <TeamCard {...member} />
            ))}
          </div>
        </section>
        <section className={`careerForm ${sliceData.isDark && "dark-theme"}`}>
          <h1>Work Where You Thrive</h1>
          <p>
            We're a company in constant motion, pushing boundaries and
            innovating every day. <br /> If you're a go-getter who
            thrives on challenge and collaboration, we want to hear from
            you. <br />
            Become part of our dynamic team and help us shape the future
            of the Transportation Industry.
          </p>
          <form className="form" onSubmit={handleSubmit}>
            <div className="flex">
              <div className="formfield">
                <label>*Name</label>
                <input
                  name="name"
                  type="text"
                  onChange={handleChange}
                  placeholder="Eg. ABC XYZ"
                />
              </div>
              <div className="formfield">
                <label>*Email</label>
                <input
                  name="email"
                  type="email"
                  onChange={handleChange}
                  placeholder="Eg. abc@xyz.com"
                />
              </div>
            </div>

            <div className="flex">
              <div className="formfield">
                <label>*Subject</label>
                <input
                  name="subject"
                  type="text"
                  onChange={handleChange}
                  placeholder="Eg. Applying for XYZ role"
                />
              </div>
              <div className="formfield">
                <label>Phone</label>
                <input
                  name="phone"
                  type="number"
                  onChange={handleChange}
                  placeholder="Eg. 987654321"
                />
              </div>
            </div>

            <div className="formfield">
              <label>*Message</label>
              <textarea
                name="message"
                placeholder="Message"
                onChange={handleChange}
              ></textarea>
            </div>
            <button type="submit">Send Message</button>
          </form>
        </section>
      </main>
      <Footer />
    </>
  )
}

export default Team
