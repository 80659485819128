import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  value: {
    isDark: false,
    userData: {}
  }
}

export const FunctionSlice = createSlice({
  name: 'functionslice',
  initialState,
  reducers: {
    setIsDark: (state, action) => {
      state.value.isDark = action.payload;
    },
    setUsersData: (state, action) => {
      state.value.userData = action.payload;
    }
  }
})

export const { setIsDark, setUsersData } = FunctionSlice.actions
export default FunctionSlice.reducer