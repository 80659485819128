import React from 'react'
import { Link } from "react-router-dom"
import "./HomeCard.css"
function HomeCard({ cardDetails }) {
  const { title, subheading, price, img, url } = cardDetails

  return (
    <Link to={`/products/${url}`}>
      <section
        className="home-product-card"
        style={{ background: `url(${img[0]})` }}
      >
        <div className="home-product-card-overlay"></div>
        <div className="home-product-card-text">
          <h1>{title}</h1>
          <p>{subheading}</p>
        </div>
        <div className="home-product-card-cta">
          <button>Explore</button>
        </div>
      </section>
    </Link>
  )
}

export default HomeCard
