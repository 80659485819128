import axios from "axios"

const API_URL = process.env.REACT_APP_API_URL;
export const handlePostApis = async (endPoint, data, headers) => {
  try {
    const response = await axios.post(`${API_URL}${endPoint}`, data, { headers: headers });
    return response.data;
  } catch (error) {
    console.error('Error posting API:', error);
  }
}

export const handleGetApis = async (endPoint, headers) => {
  try {
    const response = await axios.get(`${API_URL}${endPoint}`, { headers });
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    // throw error; // re-throwing the error to be handled by the caller if needed
  }
};