import React, { useEffect, useRef, useState } from 'react';
import "./LineChart.css";
import Chart from 'chart.js/auto';
import zoomPlugin from 'chartjs-plugin-zoom';

Chart.register(zoomPlugin);

function LineChart({ chartData, timeScale }) {
  const canvas = useRef();
  const [chartInstance, setChartInstance] = useState(null);

  useEffect(() => {
    if (!chartData || chartData.length === 0) return;

    if (chartInstance) chartInstance.destroy();

    const data = Array.isArray(chartData)
      ? chartData.map(e => e.data)
      : chartData?.data || [];
    let labels = data?.map(d => d.datetime);

    let exits = null, entries = null, occupancy = [];
    exits = data.map(d => d["total exit count"] || d.data);
    entries = data.map(d => d["total entry count"] || d.data);
    occupancy = data.map(d => {
      if (d["vehicle_no"] || (d["occupancy"] && d["occupancy"].length === 0)) {
        return null;
      }
      return d["occupancy"] || d.data;
    }).filter(o => o !== null);

    const config = {
      type: 'line',
      data: {
        labels: labels,
        datasets: [
          {
            label: "Entry Count",
            data: entries,
            fill: false,
            borderColor: 'green',
            tension: 0.1
          }, {
            label: "Exit Count",
            data: exits,
            fill: false,
            borderColor: "red",
            tension: 0.1
          }
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          zoom: {
            pan: {
              enabled: true,
              mode: 'x',
            },
            zoom: {
              wheel: {
                enabled: true,
              },
              pinch: {
                enabled: true,
              },
              mode: 'x',
            },
          },
        }
      }
    };

    config.data.datasets.push({
      label: "Occupancy",
      data: occupancy,
      fill: false,
      borderColor: 'blue',
      tension: 0.1
    });

    const chart = new Chart(canvas.current, config);
    setChartInstance(chart);

    return () => {
      if (chart) chart.destroy();
    };
  }, [chartData, timeScale]);

  return (
    <>
      <section className="lineChartContainer">
        <canvas className='canvass' ref={canvas}></canvas>
      </section>
    </>
  );
}

export default LineChart;
