import React, { useState } from 'react'
import { handlePostApis } from '../../../Utilities/Utils';
import loaderImage from "../../../Assets/loaderImage.png";
import "../Login/Login.css";
import { Link } from 'react-router-dom';
function Register() {
  const [phone, setPhone] = useState()
  const [url, setUrl] = useState(null)
  const [loading, setLoading] = useState(false)

  const handleRegister = () => {
    setLoading(true)
    const response = handlePostApis("users/register", { phone });
    response.then(function (result) {
      if (result) {
        if (result?.message) {
          alert(result?.message)
        }
        console.log(result, "register ")
        setUrl(result.data);
        setLoading(false)
      }
    })
    // fetch(`${API_URL}users/register`, {
    //   method: "POST",
    //   headers: {
    //     'content-type': "application/json"
    //   },
    //   body: JSON.stringify({ phone })
    // }).then(res => res.json())
    //   .then(res => setUrl(res.data))
    //   .catch(err => alert(err))
    //   .finally(() => setLoading(false))
  }
  return (
    <>
      <section className="login_section" style={{
        height: "100vh",
        display: "grid",
        placeItems: "center"
      }}>
        <div className="body_con">
          <div className="login_navbar">
            <div>
              {/* <img src="/Images/iitr-black.png" alt="" /> */}
              <img src="/Images/logo-black.png" alt="" />
            </div>
            <p className="header_login_screen">Registration Screen</p>
          </div>
          <div className="main_con">
            <div className="info_con" style={{ display: "flex", flexDirection: "column", gap: "20px", alignItems: "center" }}>
              <div className="heading">
                P A R I M I T R A
              </div>
              <div>
                <legend className="login_legends">Enter your Phone Number: </legend>
                <input className="login_inputs" type="number" onChange={(e) => setPhone(e.target.value)} />
                <p style={{ fontSize: "0.8rem", color: "red" }}>{phone?.length < 10 && "Should be of 10 digits"}</p>
              </div>

              {!loading ? <button className="login_btn" onClick={handleRegister}>Register</button> :
                <div className="login_btn loaderImg-holder">
                  <img src={loaderImage} alt="" className="signup-page-loaderImg" />
                </div>
              }
              {url ? <img src={url} alt="" /> : <></>}
              <p className="signup_warning">After Scanning the QR Code, kindly login</p>
              <p className="login_redirect">Already have a account? <Link to={"/app/login"}><span> Login Here</span></Link></p>
            </div>
            <div className="photo_con">
              <img className="login_photo" src="/Images/login.png" alt="" />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Register
