import React, { useContext, useEffect } from 'react'
import { cards } from '../../Services/Card';
import { Link } from 'react-router-dom';
import HomeCard from './Home_Card/HomeCard';
import "./Home.css"
import Navbar from '../../Common/Navbar/Navbar';
import Footer from '../../Common/Footer/Footer';
import { useSelector } from 'react-redux';
function Home() {
  const functionSlice = useSelector(state => state.functionslice.value);
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <Navbar />
      <section className="home-hero-container">
        <h1>Revolutionizing Mobility with Intelligent Solutions</h1>
        <Link className="home-contact" to="/contact">
          <button>Contact Us</button>
        </Link>
      </section>

      <section className={`home-about-container ${functionSlice?.isDark && "dark-theme"}`}>
        <h1>
          At Parimitra, we are redefining the future by bringing it into the present. Our unwavering commitment to excellence ensures that today's innovations set the stage for tomorrow's advancements. We actively shape the future with cutting-edge technologies.
        </h1>
        <p>
          Embodying the "Make in India" vision, Parimitra creates smart transportation solutions uniquely designed for India's roads. Using proprietary data sets and models tailored for India's diverse traffic, our breakthrough products are developed in India, for India, driving local innovation and boosting the national economy.
        </p>
        <Link to="/about">
          <button>To know more &gt;</button>
        </Link>
        <img src="/Images/make-in-india.png" alt="" />
      </section>

      <section className={`home-cards-container ${functionSlice?.isDark && "dark-theme"}`} id="product">
        <h1>Explore our Solutions</h1>
        <section className="home-cards">
          {cards.map((card) => (
            <HomeCard cardDetails={card} />
          ))}
        </section>
        {/* <p>View more &gt;</p> */}
      </section>
      <Footer />
    </>
  )
}

export default Home
