import React from 'react'
import AppNavbar from '../../Common/App_Navbar/AppNavbar'
import "./Dashboard.css"
function Dashboard() {
  return (
    <>
      <AppNavbar />
      <div className='dashboardContainer'></div>
    </>
  )
}

export default Dashboard
