import React, { useEffect, useState } from 'react'
import AppNavbar from '../../Common/App_Navbar/AppNavbar'
import { handleGetApis, handlePostApis } from '../../../Utilities/Utils'
import LineChart from '../../Common/LineChart/LineChart'
import "./TransitEye.css";
import { formatDateTime } from '../../Services/Functions';
import LiveBus from './Live_Bus/LiveBus';
import dwn from "../../../Assets/save-button.jpg";

function TransitEye() {
  const [data, setData] = useState(null);
  const [devices, setDevices] = useState([]);
  const [deviceData, setDeviceData] = useState({});
  const [timeScale, setTimeScale] = useState('d');
  const [dataType, setDataType] = useState('plain');
  const [chartData, setChartData] = useState()
  const [from, setFrom] = useState(null)
  const [analysisType, setAnalysisType] = useState(null)
  const [to, setTo] = useState(null)
  const [routeID, setRouteID] = useState('')
  useEffect(() => {
    const response = handleGetApis("transit/list");
    response.then(function (result) {
      if (result) {
        setDevices(result?.collections);
      }
    });
  }, []);
  const handleDeviceInfo = (e) => {
    console.log(e.target.value);
    const response = handlePostApis("transit/route-stats", { collectionName: e.target.value });
    response.then(function (result) {
      console.log(result)
      if (result) {
        console.log(result, "line 32")
        if (analysisType == 'aggregate') {
          let newData = calculateCumulativeSums(result);
          setChartData(newData)
          setDeviceData(newData)
        }
        else {
          setChartData(result);
          setDeviceData(result);
        }
      }
    }).catch(function (error) {
      console.error("Error:", error);
    });
  };
  useEffect(() => {
    setChartData(null)
  }, [analysisType])
  // useEffect(() => {
  //   // h d w m
  //   if (timeScale === 'h') {
  //     const now = new Date();
  //     const currentTime = formatDateTime(now);
  //     setTo(currentTime);
  //     now.setHours(now.getHours() - 6);
  //     const adjustedTime = formatDateTime(now);
  //     setFrom(adjustedTime);
  //   }
  //   else if (timeScale === 'd') {
  //     const now = new Date();
  //     const currentTime = formatDateTime(now);
  //     setTo(currentTime);

  //     now.setHours(now.getHours() - 24);
  //     const adjustedTime = formatDateTime(now);
  //     setFrom(adjustedTime);
  //   }
  //   else if (timeScale === 'w') {
  //     const now = new Date();
  //     const currentTime = formatDateTime(now);
  //     setTo(currentTime);

  //     now.setDate(now.getDate() - 7)
  //     const adjustedTime = formatDateTime(now);
  //     setFrom(adjustedTime);
  //   }
  //   else if (timeScale === 'm') {
  //     const now = new Date();
  //     const currentTime = formatDateTime(now);
  //     setTo(currentTime);

  //     now.setDate(now.getMonth() - 1)
  //     const adjustedTime = formatDateTime(now);
  //     setFrom(adjustedTime);
  //   }
  // }, [timeScale])


  function calculateCumulativeSums(data) {
    let cumulativeEntries = 0;
    let cumulativeExits = 0;

    return data && data.data.map(item => {
      // Clone the item and its data to avoid mutation
      let newItem = { data: {} };
      // Extract current entry and exit counts
      const entryCount = item['total entry count'];
      const exitCount = item['total exit count'];
      // Update cumulative counts
      if (entryCount != null) {
        cumulativeEntries += entryCount;
        newItem.data['total entry count'] = cumulativeEntries;
      } else {
        newItem.data['total entry count'] = undefined;
      }

      if (exitCount != null) {
        cumulativeExits += exitCount;
        newItem.data['total exit count'] = cumulativeExits;
      } else {
        newItem.data['total exit count'] = undefined;
      }
      // Calculate occupancy and add to the data
      const occupancy = cumulativeEntries - cumulativeExits;
      newItem.data['occupancy'] = occupancy;
      newItem.data['datetime'] = item.datetime;
      // Add the cumulative counts to the item
      newItem.totalEntryCount = cumulativeEntries;
      newItem.totalExitCount = cumulativeExits;
      return newItem;
    });
  }

  const handleDownloadData = () => {
    const csvRows = [];
    const headers = ["RouteID", ...Object.keys(chartData?.data[0])];
    csvRows.push(headers.join(','));

    chartData.data.forEach((row, index) => {
      const values = headers.map(header => {
        if (header === "RouteID") {
          return routeID;
        }
        const value = row[header];
        return typeof value === 'string' && value.includes(',') ? `"${value}"` : value;
      });
      csvRows.push(values.join(','));
    });

    const csvString = csvRows.join('\n');
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${routeID}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  return (
    <>
      <AppNavbar />
      <section className="mainContainer">
        <section className="dashboardContainer">
          <h1>TransitEye Dashboard</h1>
          <select onChange={(e) => { setAnalysisType(e.target.value) }}>
            <option value="default" disabled selected>Select Analysis Type</option>
            <option value="occupancy">Occupancy Analysis</option>
            <option value="live-bus">Live Bus Analysis</option>
            <option value="aggregate">Aggregate Analysis</option>
          </select>
          {analysisType === "occupancy" && (
            <>
              <select style={{ marginInline: "35px" }} onChange={(e) => { { handleDeviceInfo(e) }; setRouteID(e.target.value) }}>
                <option value="default" disabled selected>Select a Route</option>
                {devices?.map((item, index) => (
                  <option key={index} value={item}>{item}</option>
                ))}
              </select>

              {deviceData && chartData && (
                <>
                  <div className="infoCont">
                    <div className="infoBox">
                      <h2>{deviceData?.totalEntryCount}</h2>
                      <p>Number of entries</p>
                    </div>
                    <div className="infoBox" style={{ border: "3px solid red" }}>
                      <h2>{deviceData?.totalExitCount}</h2>
                      <p>Number of exits</p>
                    </div>
                  </div>
                  <div className='linechart'>
                    <button style={{ display: chartData && chartData.length !== 0 ? "flex" : "none" }} className='dwn-btn' onClick={handleDownloadData}> <img src={dwn} /></button>
                    <LineChart chartData={chartData} timeScale={timeScale} />
                  </div>
                </>
              )}
            </>
          )}
          {analysisType == "live-bus" && (
            <>
              <LiveBus />
            </>
          )}

          {analysisType == "aggregate" && (
            <>
              <select style={{ marginInline: "35px" }} onChange={(e) => { { handleDeviceInfo(e) }; setRouteID(e.target.value) }}>
                <option value="default" disabled selected>Select a Route</option>
                {devices?.map((item, index) => (
                  <option key={index} value={item}>{item}</option>
                ))}
              </select>

              <div className='linechart'>
                <button style={{ display: chartData && chartData.length !== 0 ? "flex" : "none" }} className='dwn-btn' onClick={handleDownloadData}> <img src={dwn} /></button>
                <LineChart chartData={chartData} timeScale={timeScale} />
              </div>
            </>

          )}

        </section>
      </section>
    </>
  )

}

export default TransitEye;
