import React from 'react'
import Routing from './Router/Routing'
import { Provider } from 'react-redux'
import { store } from './Toolkit/Store'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import 'leaflet/dist/leaflet.css';
import "./App.css"
function App() {

  return (
    <>
      <Provider store={store}>
        <Routing />
      </Provider>
    </>
  )
}

export default App
