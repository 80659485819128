export const cards = [
  {
    url: "transiteye",
    title: "TransitEye",
    subheading:
      "Effortlessly track bus boarding and alighting for seamless travel.",
    price: "TBD",
    img: ["/Images/Products/transiteye2.png", "/Images/Products/transiteye3.png"],
    data: {
      head: "Our state-of-the-art <span class='accent-color'>passenger counting system</span> meticulously tracks the boarding and alighting of passengers on buses, ensuring a seamless and efficient travel experience. ",
      para: "Designed to integrate flawlessly with existing transit systems, it provides vital data to both transit providers and passengers, facilitating smoother and more reliable journeys. The system adeptly handles simultaneous entries and exits through multiple doors, maintaining an accurate count of passengers at all times. Tailored specifically for Indian scenarios, it leverages data collected from local setups to ensure precision and relevance. A key feature of our system is the calculation of the total number of people inside the bus, delivering this information in real-time to authorities and stakeholders. This ensures optimized bus operations and enhanced passenger safety. The system transmits only the necessary count data in real-time, maintaining a balance between efficiency and privacy. Embrace a new era of intelligent transit solutions with our advanced passenger counting system, designed to revolutionize public transportation in India and beyond.",
    },
  },
  {
    url: "crowdeye",
    title: "CrowdEye",
    subheading:
      "This wireless, out-of-the-box device uses advanced algorithms to accurately count people in public spaces.",
    price: "TBD",
    img: ["/Images/Products/crowdeye1.png"],
    data: {
      head: "Introducing our cutting-edge <span class = 'accent-color'>Crowd Alert and Management System </span>, an essential tool for maintaining safety and efficiency in public spaces",
      para: "This completely wireless, out-of-the-box device leverages state-of-the-art algorithms to accurately detect and count the number of individuals entering and exiting an enclosed area. When a certain crowd threshold is crossed, the system instantly alerts authorities, ensuring timely intervention to prevent overcrowding. Designed for seamless integration, this plug-and-play device is remarkably easy to install and use. It provides real-time data and alerts not only to authorities but also to the public, fostering greater awareness and enabling proactive crowd management. By efficiently monitoring and managing crowd sizes, it helps to prevent potential disasters like stampedes, ensuring a safer environment for everyone. <br />Our system's advanced capabilities make it an indispensable asset for venues of all sizes, from stadiums and concert halls to shopping malls and temples. With this innovative technology, you can ensure the safety and well-being of your visitors, while also enhancing the overall efficiency of your operations. Embrace the future of crowd management with our state-of-the-art solution and transform the way you manage public safety.",
    },
  },
  {
    url: "video-analytics",
    title: "Video Analytics using ITD",
    subheading:
      "Transform traffic videos into actionable insights with accurate vehicle counts by category.",
    price: "TBD",
    img: [
      "/Images/Products/itd2.png",
      "/Images/Products/itd1.png",
      "/Images/Products/itd3.png",
      "/Images/Products/itd4.png",
      "/Images/Products/itd5.png",
      "/Images/Products/itd6.png",
      "/Images/Products/itd7.png",
    ],
    data: {
      head: "With the Indian Traffic Dataset, <span class='accent-color'>PARIMITRA</span> provide an essential tool for researchers and practitioners alike, fostering advancements in traffic management and intelligent transportation systems, particularly tailored to the unique dynamics of developing economies.",
      para: "Efficient transportation is essential for national progress. Modern Intelligent Transportation Systems (ITS) rely on large-scale driving datasets, but existing datasets fall short in addressing the diverse vehicle types found in developing economies like India and in utilizing real-world video and image data from static cameras. To bridge this gap, we present the Indian Traffic Dataset (ITD). <br /> <br />The ITD captures the diverse traffic of India, featuring a variety of vehicles from different regions. It includes 17,666 annotated images, 7,778 of which are novel, spanning nine distinct classes and nearly 341,000 annotated objects. Unique to ITD is the comprehensive labeling of vehicle classes according to the Indian Highway Capacity Manual (INDO-HCM), making it especially relevant for South and Southeast Asian countries with similar traffic landscapes. <br /> <br />Validated with no significant difference between observed and predicted traffic volumes at a 5% significance level, the ITD covers a wide range of traffic conditions. It supports tasks such as vehicle counting, traffic flow analysis, trajectory analysis, safety assessments, and traffic violation detection, making it an invaluable resource for various ITS applications.",
    },
  },
]

export const team = [
  {
    name: "Prof. Amit Agarwal",
    designation: "Founder & Director",
    linkedin: "https://www.linkedin.com/in/amit-agarwal-99a20232/",
    email: "amitfce@iitr.ac.in",
    img: "/Images/amit.jpg",
  },
  {
    name: "Karthik Krishnan O",
    designation: "CoFounder & Director",
    linkedin:
      "https://www.linkedin.com/in/karthik-krishnan-o-75846920b/",
    email: "karthikkrishnanozhinhala@gmail.com",
    img: "/Images/karthik.jpg",
  },
]