import React from 'react'
import { useSelector } from 'react-redux'
import { Carousel } from 'react-responsive-carousel'
import { useParams } from 'react-router-dom';
import { cards } from '../../Services/Card';
import "./Products.css";
import Navbar from '../../Common/Navbar/Navbar';
import Footer from '../../Common/Footer/Footer';
function Products() {
  const { name } = useParams()
  const sliceData = useSelector((state) => state.functionslice.value);
  const details = cards.filter((e) => e.url === name)[0]
  return (
    <>
      <Navbar />
      <main className={`product-page ${sliceData.isDark && "dark-theme"}`}>
        <section className="product-page-first-section">
          <div>
            <h1>{details?.title}</h1>
            <p>{details?.subheading}</p>
            <div>
              <a href="#product-content">
                <button className="product-btn-2">
                  Explore More
                </button>
              </a>
              {(name === "video-analytics" || name === "crowdeye") &&
                <a href={`/Assets/${name}.pdf`} filename="Indian Traffic Dataset" download target="__blank">
                  <button className="product-btn-1">
                    Product Flyer
                  </button>
                </a>}
            </div>
          </div>
          <Carousel
            showArrows={true}
            autoPlay={true}
            showThumbs={false}
            width={window.innerWidth > 768 ? "50vw" : "90vw"}
            infiniteLoop={true}
            interval={3000}
          >
            {details?.img.map((i) => (
              <div>
                <img src={i} alt="" />
              </div>
            ))}
          </Carousel>
        </section>

        <section
          id="product-content"
          className="product-page-second-section"
        >
          <h1
            dangerouslySetInnerHTML={{ __html: details?.data?.head }}
          ></h1>
          <p
            dangerouslySetInnerHTML={{ __html: details?.data?.para }}
          ></p>
        </section>
      </main>
      <Footer />
    </>
  )
}

export default Products
