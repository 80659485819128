import React, { useEffect, useState } from 'react'
import AppNavbar from '../../Common/App_Navbar/AppNavbar'
import { handleGetApis, handlePostApis } from '../../../Utilities/Utils'
import { useSelector } from 'react-redux'
import { formatDateTime } from '../../Services/Functions'
import LineChart from '../../Common/LineChart/LineChart'
import "./CrowdEye.css"
import loaderImage from "../../../Assets/loaderImage.png";
import { useNavigate } from 'react-router-dom'
import dwn from "../../../Assets/save-button.jpg";
import { DatePicker } from 'antd';
const { RangePicker } = DatePicker;

function CrowdEye() {
  const [data, setData] = useState([])
  const [chartData, setChartData] = useState(null)
  const [dataType, setDataType] = useState("plain")
  const [serviceSelected, setServiceSelected] = useState("crowdeye")
  const [timeScale, setTimeScale] = useState('custom')
  const [deviceID, setDeviceID] = useState('')
  const [deviceInfo, setDeviceInfo] = useState()
  const [lastRefresh, setLastRefresh] = useState(null)
  const [from, setFrom] = useState(null)
  const [to, setTo] = useState(null)
  const [devices, setDevices] = useState(null)
  const [total, setTotal] = useState({})
  const sliceData = useSelector((state) => state.functionslice.value);
  const headers = {
    'Content-Type': "application/json",
    'permissions': sliceData?.userData?.permissions
  }
  const handleToggle = () => {
    setDataType(dataType === "plain" ? "cumulative" : "plain");
  };
  const nav = useNavigate();
  function calculateCumulativeSums(data) {
    let cumulativeEntries = 0;
    let cumulativeExits = 0;

    return data && data?.map(item => {
      let newItem = { ...item, data: { ...item?.data } };

      let entryCount = item?.data['total entry count'];
      let exitCount = item?.data['total exit count'];

      if (entryCount != null && entryCount !== undefined) {
        cumulativeEntries += entryCount;
        newItem.data['total entry count'] = cumulativeEntries;
      } else {
        newItem.data['total entry count'] = undefined;
      }

      if (exitCount != null && exitCount !== undefined) {
        cumulativeExits += exitCount;
        newItem.data['total exit count'] = cumulativeExits;
      } else {
        newItem.data['total exit count'] = undefined;
      }

      return newItem;
    });
  }


  useEffect(() => {
    if (sliceData?.userData?.phone) {
      const response = handlePostApis("devices/list", { phone: sliceData.userData.phone });
      response.then(function (result) {
        if (result) {
          setDevices(result?.data)
        }
      })
    }
  }, [sliceData?.userData?.phone])
  useEffect(() => {
    if (deviceID && sliceData?.userData?.permissions) {
      const headers = {
        'content-type': "application/json",
        'permissions': sliceData.userData.permissions
      }
      const response = handleGetApis(`devices/${deviceID}`, headers);
      response.then(function (result) {
        if (result) {
          setDeviceInfo(result);
        }
      })
    }

  }, [deviceID, sliceData?.userData?.permissions])

  const deviceSelection = (deviceId, from, to) => {
    console.log("Sending data:", { deviceId, from, to });
    const response = handlePostApis("devices/data", { deviceId, from, to });
    response.then(function (result) {
      if (result) {
        console.log(result, "line 129")
        setData(result?.data);
        setLastRefresh((new Date(result?.data[result?.data?.length - 1]?.data?.datetime)).toLocaleString('en-us', {
          weekday: "long", year: "numeric", month: "short", day: "numeric", hour: "2-digit", minute: "2-digit", second: "2-digit"
        }))
        const totalEntries = result?.data.reduce((acc, curr) => acc + (curr?.data['total entry count'] ?? 0), 0)
        const totalExits = result?.data.reduce((acc, curr) => acc + (curr?.data['total exit count'] ?? 0), 0)
        setTotal({
          entry: totalEntries,
          exit: totalExits
        })
        if (dataType === 'cumulative') {
          let newData = calculateCumulativeSums(result?.data);
          setChartData(newData);
        }
        else {
          setChartData(result?.data);
        }
      }
    })
  };
  useEffect(() => {
    deviceSelection(deviceID, from, to)
  }, [from, to, deviceID, dataType])
  const handleAggregate = (e) => {
    const data = e.target.value;
    if (data === "Aggregate") {
      nav("/app/crowdeye/aggregate");
    }
  }

  const handleDownloadData = () => {
    const csvRows = [];
    const headers = ['id', 'address', 'deviceID', ...Object.keys(chartData[0].data)];  // Add 'address' and 'deviceID'
    csvRows.push(headers.join(','));

    chartData.forEach(row => {
      const values = [
        row.id,
        `"${deviceInfo?.Address}"`,
        deviceID,
        ...headers.slice(3).map(header => row.data[header])
      ];
      csvRows.push(values.join(','));
    });

    const csvString = csvRows.join('\n');
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${deviceID}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const handleDate = (obj, strings) => {
    const fromDate = new Date(strings[0])
    const toDate = new Date(strings[1])
    setFrom(fromDate);
    setTo(toDate);
    console.log(fromDate, "fromDate", toDate)
    const temp = data
    console.log(devices)
    devices.forEach(deviceId => {
      const response = handlePostApis("devices/data", { from: from, to: to, deviceId: deviceId }, headers);
      response.then(function (result) {
        if (result) {
          console.log(result, "line 212")
          temp[deviceId] = [...result.data];
          setLastRefresh((new Date(result?.data[result?.data?.length - 1]?.data?.datetime)).toLocaleString('en-us', {
            weekday: "long", year: "numeric", month: "short", day: "numeric", hour: "2-digit", minute: "2-digit", second: "2-digit"
          }))
          const totalEntries = result?.data.reduce((acc, curr) => acc + (curr?.data['total entry count'] ?? 0), 0)
          const totalExits = result?.data.reduce((acc, curr) => acc + (curr?.data['total exit count'] ?? 0), 0)
          setTotal({
            entry: totalEntries,
            exit: totalExits
          })
          if (dataType === 'cumulative') {
            let newData = calculateCumulativeSums(result?.data);
            setChartData(newData);
          }
          else {
            setChartData(result?.data);
          }
        }
      })
    })
  }

  // console.log(deviceInfo.Location, "deviceInfo.LocationdeviceInfo.Location", deviceInfo.Address)
  console.log(data, "data 223")
  // if (data && data !== null) {
  return (
    <>
      <AppNavbar />
      <section className="mainContainer">
        <section className="dashboardContainer">
          <h1 className='pagetag'>Crowdeye Dashboard</h1>
          <>
            <select onChange={(e) => { { setDeviceID(e.target.value) }; handleAggregate(e) }}>
              <option value="default" disabled selected>Select a Device</option>
              {devices?.map((item) => (
                <option value={item}>{item}</option>
              ))}
              <option style={{ display: devices?.length !== 0 ? "flex" : "none" }} value="Aggregate">Aggregate Analysis</option>
            </select>

            {deviceInfo ?
              <>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <div>

                    <p className='location'>Location: - {deviceInfo.Address ?? ""}</p>
                    {deviceInfo.Location ?
                      <p>Latitude: - {deviceInfo.Location._latitude}, Longitude: - {deviceInfo.Location._longitude}</p>
                      : <></>}
                    <p className='updated'>Last Updated on: {lastRefresh}</p>
                  </div>
                  {/* <div>
                                <button className={refreshButton}>Refresh</button>
                            </div> */}
                </div>
                {total &&
                  <div className="infoCont">
                    <div className="infoBox">
                      <h2>{total?.entry}</h2>
                      <p>Number of entries</p>
                    </div>
                    <div className="infoBox" style={{ border: "3px solid red" }}>
                      <h2>{total?.exit}</h2>
                      <p>Number of exits</p>
                    </div>
                  </div>
                }
                <div className="options_tab">
                  {/* <select onChange={(e) => {
                        setTimeScale(e.target.value)
                      }}>
                        <option value="h" selected>Last 6 hours</option>
                        <option value="d">Last 24 hours</option>
                        <option value="w">Last week</option>
                        <option value="m">Last month</option>
                        <option value="custom">Custom</option>
                      </select> */}

                  {timeScale === "custom" && <RangePicker showTime={true} allowClear={false} onChange={handleDate} />}

                  <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>

                    <div className={`toggle_switch ${dataType === 'plain' ? "plain" : "cumulative"}`} onClick={handleToggle}>
                      <div className="switch"></div><br />
                    </div>
                    <p>Cumulative</p>
                  </div>
                </div>
                <button style={{ display: chartData && chartData.length === 0 ? "flex" : "none", cursor: "not-allowed" }} className='dwn-btn' > <img src={dwn} /></button>
                <div className='linechart'>
                  <button style={{ display: chartData && chartData.length !== 0 ? "flex" : "none" }} className='dwn-btn' onClick={handleDownloadData}> <img src={dwn} /></button>
                  <LineChart chartData={chartData} timeScale={timeScale} />

                </div>
              </>
              : <p>Please Select a device before continuing...</p>}
          </>

        </section>
      </section >
    </>
  )
  // } else {
  //   return (
  //     <>
  //       <AppNavbar />
  //       <div style={{ height: "100vh", width: "100vw", display: "flex", alignItems: "center", justifyContent: "center" }}>
  //         <img style={{ height: "15rem" }} src={loaderImage} alt="" className="signup-page-loaderImg" />
  //       </div>
  //     </>
  //   )
  // }

}

export default CrowdEye
