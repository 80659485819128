import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { handleGetApis, handlePostApis } from '../../../Utilities/Utils';
import tt from "@tomtom-international/web-sdk-maps"
import { formatDateTime, handleFormatDateTime } from '../../Services/Functions';
import Chart from "chart.js/auto"
import { DatePicker } from 'antd';
import loaderImage from "../../../Assets/loaderImage.png"
import AppNavbar from '../../Common/App_Navbar/AppNavbar';
import "./Aggregate.css";
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import L from 'leaflet';
import dwn from "../../../Assets/save-button.jpg";
import zoomPlugin from 'chartjs-plugin-zoom';

Chart.register(zoomPlugin);

const { RangePicker } = DatePicker;
const calibrationCount = 18000; // based on video processing on 12 July at 21:30

function Aggregate() {
  const [data, setData] = useState({})
  const [timeScale, setTimeScale] = useState('custom')
  const [aggregate, setAggregate] = useState(null)
  const [chartData, setChartData] = useState(null)
  const [status, setStatus] = useState([]);
  const [tomtom, settomtom] = useState(null)
  const [show, setShow] = useState("occupancy")
  const [devices, setDevices] = useState([])
  const [keyData, setKeyData] = useState([])
  const sliceData = useSelector((state) => state.functionslice.value);
  const [customData, setCustomData] = useState()
  const [downloadData, setDownloadData] = useState({})
  const mapContainer = useRef()
  const headers = {
    'Content-Type': "application/json",
    'permissions': sliceData?.userData?.permissions
  }

  const canvas = useRef()
  const [chartInstance, setChartInstance] = useState(null)

  function generateTimeIntervals(endDate, hoursBack, intervalMinutes) {
    const intervals = [];
    const startDate = new Date(endDate);
    console.log(startDate, "startdate 41");
    startDate.setHours(startDate.getHours() - hoursBack);
    startDate.setMinutes(Math.floor(startDate.getMinutes() / 5) * 5); // Round down to the nearest 5 minutes
    startDate.setSeconds(0, 0); // Reset seconds and milliseconds

    for (let date = new Date(startDate); date <= endDate; date.setMinutes(date.getMinutes() + intervalMinutes)) {
      intervals.push({
        datetime: new Date(date),
        'total entry count': 0,
        'total exit count': 0
      });
    }

    return intervals;
  }

  function aggregateCounts(timeIntervals, dataArrays) {
    Object.keys(dataArrays).forEach(key => {
      let intervalIndex = 0;
      const dataArray = dataArrays[key];
      dataArray.forEach((device) => {
        // Find the nearest 5-minute interval for the datetime
        const roundedDate = new Date(Math.round((new Date(device.data.datetime)).getTime() / (5 * 60 * 1000)) * (5 * 60 * 1000));
        // Move the interval index forward to match the roundedDate
        while (intervalIndex < timeIntervals.length && timeIntervals[intervalIndex].datetime < roundedDate) {
          intervalIndex++;
        }

        // If we found the matching interval, add the count
        if (intervalIndex < timeIntervals.length && timeIntervals[intervalIndex].datetime.getTime() === roundedDate.getTime()) {
          timeIntervals[intervalIndex]['total entry count'] += device.data['total entry count'];
          timeIntervals[intervalIndex]['total exit count'] += device.data['total exit count'];
        }
      });
    });

    return timeIntervals;
  }

  function aggregateDataArrays(dataArrays, hoursBack) {
    console.log(dataArrays, hoursBack, "line 81")
    const endDate = new Date();
    const intervalMinutes = 5;

    const timeIntervals = generateTimeIntervals(endDate, hoursBack, intervalMinutes);

    const aggregatedData = aggregateCounts(timeIntervals, dataArrays);
    return aggregatedData;
  }

  useEffect(() => {
    if (!devices) return;

    let now = new Date();
    let hoursBack = 0;

    // Dynamically set hoursBack based on selected timescale
    if (timeScale === "h") {
      hoursBack = 6;
    } else if (timeScale === "d") {
      hoursBack = 24;
    } else if (timeScale === "w") {
      hoursBack = 24 * 7;
    } else if (timeScale === "m") {
      hoursBack = 24 * 30;
    }

    const to = formatDateTime(now);
    now.setHours(now.getHours() - hoursBack);
    const from = formatDateTime(now);

    const temp = data;
    devices.forEach(deviceId => {
      const response = handlePostApis("devices/data", { from: from, to: to, deviceId: deviceId }, headers);
      response.then(function (result) {
        if (result) {
          temp[deviceId] = [...result.data];
          setData({ ...temp });
          setDownloadData({ ...temp })

        }
      });
    });
  }, [devices]);

  useEffect(() => {
    if (!sliceData?.userData || !devices) return;
    if (Object.keys(data).length < devices.length) return;

    let hoursBack = 0;
    if (timeScale === "h") {
      hoursBack = 6;
    } else if (timeScale === "d") {
      hoursBack = 24;
    } else if (timeScale === "w") {
      hoursBack = 24 * 7;
    } else if (timeScale === "m") {
      hoursBack = 24 * 30;
    }

    const aggregateData = aggregateDataArrays(data, hoursBack);
    setAggregate(aggregateData);

    const fetchData = async () => {
      const result = await addDeviceStatus(data);
      setStatus(result);
    };

    console.log("aggregateData before filter and cumulative", aggregateData);

    fetchData();
  }, [data, timeScale]);


  useEffect(() => {
    setCustomData(null);
  }, [timeScale])
  function calculateCumulativeSums(data) {
    let cumulativeCount = 0;
    let entries = 0
    let exits = 0
    return data.map(item => {
      let entry = ![NaN, undefined, null].includes(item['total entry count']) ? item['total entry count'] : 0
      let exit = ![NaN, undefined, null].includes(item['total exit count']) ? item['total exit count'] : 0
      entries += entry
      exits += exit
      cumulativeCount += entry - exit;
      return { datetime: item.datetime, count: cumulativeCount + calibrationCount, entries: entries, exits: exits }
    });
  }

  async function addDeviceStatus(data) {
    let statuses = [];

    const fetchPromises = Object.keys(data).map(async (key) => {
      const obj = { id: key };
      try {
        const response = handleGetApis(`devices/${key}`, headers);
        response.then(function (res) {
          if (res) {
            obj.lat = res.location._latitude;
            obj.long = res.location._longitude;
            obj.Address = res.Address;
          } else {
            throw new Error(`Failed to fetch device ${key}`);
          }


          const dataArray = data[key];
          if (dataArray.length > 0) {
            const lastElement = dataArray[dataArray.length - 1];
            const lastTime = new Date(lastElement.data.datetime);
            const currentTime = new Date();
            const timeDifference = currentTime - lastTime;
            const timeDifferenceInMin = timeDifference / (1000 * 60);

            obj.status = timeDifferenceInMin >= 10 ? 'inactive' : 'active';
          }

          statuses.push(obj);
        }).catch((error) => {
          console.error(`Error processing device ${key}:`, error);
        });

      } catch (error) {
        console.error(`Error initiating fetch for device ${key}:`, error);
      }
    });

    await Promise.all(fetchPromises);
    return statuses;
  }

  useEffect(() => {
    if (sliceData.userData.phone != undefined) {
      const response = handlePostApis("devices/list", { phone: sliceData?.userData?.phone }, headers);
      response.then(function (result) {
        if (result) {
          setDevices(result?.data)
          result?.data?.map((id) => {
            const response = handleGetApis(`devices/${id}`, headers);
            response.then(function (res) {
              if (res) {
                console.log(res, "220")
                setKeyData(prev => [...prev, res]);
              } else {
                // throw new Error(`Failed to fetch device ${keys}`);
              }
            })
          })
        }
      })
    }
  }, [sliceData?.userData?.phone])


  useEffect(() => {
    if (status?.length === 0) return


    if (tomtom) {
      tomtom.remove()
    }

    const map = tt.map({
      key: process.env.REACT_APP_TOMTOM,
      container: mapContainer.current,
      zoom: 12,
      center: [status[0]?.long, status[0]?.lat],
      style: `https://api.tomtom.com/style/1/style/*?map=2/basic_street-satellite&poi=2/poi_dynamic-satellite&key=${process.env.REACT_APP_TOMTOM}`,
    })

    map.on("load", () => {
      status.forEach((device) => {
        const popup = new tt.Popup({
          closeButton: false,
          offset: 25,
          anchor: "bottom",
        }).setText(device.Address)

        const marker = new tt.Marker({
          color: device.status === "active" ? "green" : "red",
        })
          .setLngLat([device.long, device.lat])
          .addTo(map)
          .setPopup(popup)

        marker.on("click", () => {
          popup.open(map, marker.getLngLat())
        })
      })
    })

    settomtom(map)
  }, [status])

  useEffect(() => {
    if (!aggregate) return
    if (timeScale === "custom") return

    let now = new Date()
    let currentTime = formatDateTime(now)
    let adjustedTime = null
    if (timeScale === "h") {
      now.setHours(now.getHours() - 6)
      adjustedTime = formatDateTime(now)
    } else if (timeScale === "d") {
      now.setHours(now.getHours() - 24)
      adjustedTime = formatDateTime(now)
    } else if (timeScale === "w") {
      now.setDate(now.getDate() - 7)
      adjustedTime = formatDateTime(now)
    } else if (timeScale === "m") {
      now.setMonth(now.getMonth() - 1)
      adjustedTime = formatDateTime(now)
    }

    const temp = data
    devices.forEach((deviceId) => {
      const response = handlePostApis("devices/data", {
        from: adjustedTime,
        to: currentTime,
        deviceId: deviceId,
      }, headers);
      response.then(function (result) {
        if (result) {
          temp[deviceId] = [...result.data];
          setData({ ...temp });
          setDownloadData({ ...temp })
        }
      })
    })
  }, [timeScale])

  useEffect(() => {
    if (!aggregate) return;
    console.log("310", aggregate)
    const cumulative = calculateCumulativeSums(aggregate)
    console.log(cumulative, "312")
    setChartData(cumulative)
  }, [aggregate])

  function calculateCumulativeSumsnew(data) {
    let cumulativeCount = 0;
    let entries = 0
    let exits = 0
    return data.map(item => {
      let entry = ![NaN, undefined, null].includes(item.data['total entry count']) ? item.data['total entry count'] : 0
      let exit = ![NaN, undefined, null].includes(item.data['total exit count']) ? item.data['total exit count'] : 0
      entries += entry
      exits += exit
      cumulativeCount += entry - exit;
      return { datetime: item.data.datetime, count: cumulativeCount + calibrationCount, entries: entries, exits: exits }
    });
  }
  useEffect(() => {
    console.log(chartData, "useeffect chartdata", customData)
    if (chartData === null) return;
    if (chartInstance) chartInstance.destroy();
    let labels = null

    if (timeScale === 'h' || timeScale === 'd') labels = chartData.map(d => new Date(d.datetime).toLocaleString("en-us", {
      hour: "numeric",
      minute: "numeric",
      hour12: false
    }))
    else labels = chartData.map(d => new Date(d.datetime).toLocaleString("en-us", {
      day: "numeric",
      month: "short",
      hour: "numeric",
      minute: "numeric",
      hour12: false
    }))
    let counts = chartData.map(d => d.count)
    let entries = chartData.map(d => d.entries)
    let exits = chartData.map(d => d.exits)
    let config = {}
    if (customData && customData !== null) {
      let allValues = [];
      Object.keys(customData).forEach((keyv) => {
        let valueArray = customData[keyv];
        allValues = allValues.concat(valueArray);
      });
      const newData = calculateCumulativeSumsnew(allValues);
      labels = newData?.map(d => new Date(d?.datetime).toLocaleString("en-us", {
        day: "numeric",
        month: "short",
        hour: "numeric",
        minute: "numeric",
        hour12: false
      }))
      counts = newData.map(d => d.count)
      entries = newData.map(d => d.entries)
      exits = newData.map(d => d.exits)
    }
    const generateConfig = (label, data, borderColor) => ({
      type: 'line',
      data: {
        labels: labels,
        datasets: [
          {
            label: label,
            data: data,
            fill: false,
            borderColor: borderColor,
            tension: 0.1
          }
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          zoom: {
            pan: {
              enabled: true,
              mode: 'x',
            },
            zoom: {
              wheel: {
                enabled: true,
              },
              pinch: {
                enabled: true,
              },
              mode: 'x',
            },
          },
        },
      }
    });

    switch (show) {
      case 'occupancy':
        config = generateConfig("Current Occupancy Count", counts, 'blue');
        break;

      case 'entries':
        config = generateConfig("Total Entries", entries, 'green');
        break;

      case 'exits':
        config = generateConfig("Total Exits", exits, 'red');
        break;

      default:
        console.error("Invalid option for 'show'");
    }


    const chart = new Chart(canvas.current, config)
    chart.update()
    setChartInstance(chart)

    return () => {
      if (chartInstance) chartInstance.destroy()
    }
  }, [chartData, show, customData])

  const handleDate = (obj, strings) => {
    const fromDate = new Date(strings[0])
    const toDate = new Date(strings[1])
    if (!aggregate) return;
    const temp = data
    devices.forEach(deviceId => {
      const response = handlePostApis("devices/data", { from: fromDate, to: toDate, deviceId: deviceId }, headers);
      response.then(function (result) {
        if (result) {
          temp[deviceId] = [...result.data];
          setCustomData({ ...temp })
          setDownloadData({ ...temp })
        }
      })
    })
  }

  const customIcon = new L.Icon({
    iconUrl: require('../../../Assets/maplogo.png'),
    iconSize: [50, 50],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  });


  const convertToCSV = (data) => {
    const allRows = [];
    const keys = Object.keys(data);
    const headers = ["Category", "Datetime", "Total Entry Count", "Total Exit Count", "ID"];
    allRows.push(headers.join(",")); // Add headers row

    // Loop through each key in data (e.g., HAR001, HAR002)
    keys.forEach((key) => {
      // Loop through each object in the array
      data[key].forEach((item) => {
        const row = [
          key, // Add category (e.g., HAR001)
          item.data.datetime || "", // Add datetime field
          item.data["total entry count"] || "", // Add total entry count
          item.data["total exit count"] || "", // Add total exit count
          item.id || "" // Add id field
        ];
        allRows.push(row.join(","));
      });
    });

    return allRows.join("\n");
  };
  const handleDownloadData = () => {
    console.log(downloadData, "download data")
    const csvData = convertToCSV(data);
    const blob = new Blob([csvData], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "AggregateData.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    (sliceData?.userData && chartData) ?
      <section>
        <AppNavbar />
        <section className="mainContainer">
          <section className="dashboardContainer">
            <h1>Aggregate Analysis</h1>

            <section style={{ display: "none" }} ref={mapContainer} className="mapContainer"></section>
            {keyData && (
              <MapContainer center={[30.5457, 78.1642]} zoom={8} style={{ height: "40vh", width: "100%" }}>
                <TileLayer
                  url="https://tile.thunderforest.com/atlas/{z}/{x}/{y}.png?apikey=cc6dadf1fc96479db163cb44ceece80c"
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
                {keyData &&
                  keyData?.map((item, id) => {
                    return (
                      <Marker
                        key={item?.locationID}
                        position={[item?.location?._latitude, item?.location?._longitude]}
                        icon={customIcon}
                      >
                        <Popup>
                          DeviceId: {item.DeviceId} <br />
                          locationID: {item.locationID} <br />
                        </Popup>
                      </Marker>
                    );
                  }
                  )}
              </MapContainer>
            )}

            <div className="flex">
              {/* <select onChange={(e) => {
                setTimeScale(e.target.value)
              }}>
                <option value="h" selected>Last 6 hours</option>
                <option value="d">Last 24 hours</option>
                <option value="w">Last week</option>
                <option value="m">Last month</option>
                <option value="custom">Custom</option>
              </select> */}
              {timeScale === "custom" && <RangePicker showTime={true} allowClear={false} onChange={handleDate} />}

              <select onChange={(e) => {
                setShow(e.target.value)
              }}>
                <option value="occupancy" selected>Current Occupancy</option>
                <option value="entries">Total Entries</option>
                <option value="exits">Total Exits</option>
              </select>
            </div>

            <section className="lineChartContainer">
              <button style={{ display: chartData && chartData.length !== 0 ? "flex" : "none" }} className='dwn-btn' onClick={handleDownloadData}> <img src={dwn} /></button>
              <canvas style={{ height: "100%", width: "100%" }} ref={canvas}></canvas>
            </section>
          </section>
        </section>
      </section>
      :
      <div style={{ height: "100vh", width: "100vw", display: "flex", alignItems: "center", justifyContent: "center" }}>
        <img style={{ height: "15rem" }} src={loaderImage} alt="" className="signup-page-loaderImg" />
      </div>
  )
}

export default Aggregate
