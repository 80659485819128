import React from 'react'
import "./TeamCard.css";
function TeamCard({
  name,
  designation,
  creds,
  email,
  linkedin,
  img,
}) {
  return (
    <>
      <div className="teamcard">
        <img src={img} alt="" />
        <h1>{name}</h1>
        <p className="designation">{designation}</p>
        <a href={linkedin} target="__blank">
          Linkedin
        </a>
        <p className="email">
          Email -<br /> <a href={`mailto:${email}`}>{email}</a>
        </p>
      </div>
    </>
  )
}

export default TeamCard
