import React, { useEffect, useState } from 'react';
import "./Login.css"
import loaderImage from "../../../Assets/loaderImage.png"
import { handlePostApis } from '../../../Utilities/Utils';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setUsersData } from '../../../Toolkit/Slices/FunctionSlice';

function Login() {
  const nav = useNavigate();
  const [user, setUser] = useState([])
  const [token, setToken] = useState(null)
  const [phone, setPhone] = useState(null)
  const [loading, setLoading] = useState(false)
  const handleLogin = async () => {
    setLoading(true);
    const response = handlePostApis("users/login", { phone, token });
    response.then(function (result) {
      console.log(result)
      if (result) {
        setUser(result.data)
        window.sessionStorage.setItem('user', JSON.stringify(result.data));
        nav('/app/dashboard');
      }
      else {
        setLoading(false)
        alert("Enter Proper credentials")
      }

    })
  }
  // useEffect(() => {
  //   const helper = async () => {
  //     const localUser = window.localStorage.getItem('user')
  //     if (localUser) {
  //       const userJSON = await JSON.parse(localUser)
  //       if (!user && userJSON) setUser(userJSON)
  //     }
  //   }
  //   helper()
  // }, []);
  return (
    <>
      <section className="login_section" style={{
        height: "100vh",
        display: "grid",
        placeItems: "center"
      }}>
        <div className="body_con">
          <div className="login_navbar">
            <div>
              {/* <img src="/Images/iitr-black.png" alt="" /> */}
              <img src="/Images/logo-black.png" alt="" />
            </div>
            <p className="header_login_screen">Login Screen</p>
          </div>
          <div className="main_con">
            <div className="info_con" style={{ display: "flex", flexDirection: "column", gap: "20px", alignItems: "center" }}>
              <div className="heading">
                P A R I M I T R A
              </div>
              <div>
                <legend className="login_legends">Enter your Phone Number: </legend>
                <input className="login_inputs" type="number" onChange={(e) => setPhone(e.target.value)} />
                <p style={{ fontSize: "0.8rem", color: "red" }}>{phone?.length < 10 && "Should be of 10 digits"}</p>
              </div>

              <div>
                <legend className="login_legends">Enter your Authenticator Token: </legend>
                <input className="login_inputs" type="number" onChange={(e) => setToken(e.target.value)} />
              </div>
              {!loading ? <button className="login_btn" onClick={handleLogin}>Log In</button> :
                <div className="login_btn loaderImg-holder">
                  <img src={loaderImage} alt="" className="signup-page-loaderImg" />
                </div>
              }
              <p className="login_redirect">Don't have a account yet? <Link to={"/app/register"}><span> REGISTER HERE </span></Link></p>
            </div>
            <div className="photo_con">
              <img className="login_photo" src="/Images/login.png" alt="" />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Login
